import { mdiHomeOutline, mdiMenu, mdiCash } from '@mdi/js'

export default [
  {
    title: 'Accueil',
    icon: mdiHomeOutline,
    to: 'dashboard-analytics',
  },

  // {
  //   title: 'vue de test',
  //   icon: mdiHomeOutline,
  //   to: 'vue-test',
  // },
  // {
  //   title: 'Département commercial',
  //   icon: mdiHomeOutline,
  //   children: [
  //     {
  //       title: 'Gestion des locaux',
  //       children: [
  //         {
  //           title: 'Enregistrer un local',
  //           to: 'save-local',
  //         },
  //         {
  //           title: 'Liste des locaux',
  //           to: 'list-local',
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   title: 'Département de réabonnement',
  //   icon: mdiHomeOutline,
  //   children: [
  //     {
  //       title: 'Gestion des opérations',
  //       children: [
  //         {
  //           title: 'Enregistrer une opération',
  //           to: 'save-operation',
  //         },{
  //           title: 'Liste des opérations',
  //           to: 'list-operation',
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   title: 'Département financier',
  //   icon: mdiHomeOutline,
  //   children: [
  //     {
  //       title: 'Finances',
  //       icon: mdiCash,
  //       children: [
  //         {
  //           title: 'Effectuer une recharge',
  //           to: 'form-recharge',
  //         },
  //         {
  //           title: 'Liste des recharges',
  //           to: 'list-recharge',
  //         },
  //       ],
  //     },
  //     {
  //       title: 'Dettes',
  //       icon: mdiCash,
  //       children: [
  //         {
  //           title: 'Etat des dettes',
  //           to: 'etat-dette',
  //         },
  //         {
  //           title: 'Remboursement de la dette',
  //           to: 'remboursement-dette',
  //         },
  //         {
  //           title: 'Liste des remboursements',
  //           to: 'liste-paiement',
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   title: 'Département des SI',
  //   icon: 'fa-code',
  //   children: [
  //     {
  //       title: 'Départements',
  //       icon: 'fa-building',
  //       children: [
  //         {
  //           title: 'Enregistrer un département',
  //           to: 'save-departement',
  //         },
  //         {
  //           title: 'Liste des départements',
  //           to: 'list-departement',
  //         },
  //       ],
  //     },
  //     {
  //       title: 'Permission catégorie',
  //       icon: 'fa-file-shield',
  //       children: [
  //         {
  //           title: 'Enregistrer une categorie',
  //           to: 'save-cat-permission',
  //         },
  //         {
  //           title: 'Liste des catégories',
  //           to: 'list-cat-permission',
  //         },
  //       ],
  //     },
  //
  //     {
  //       title: 'Permission',
  //       icon: 'fa-shield-halved',
  //       children: [
  //         {
  //           title: 'Enregistrer une permission',
  //           to: 'save-permission',
  //         },
  //         {
  //           title: 'Liste des permissions',
  //           to: 'list-permission',
  //         },
  //       ],
  //     }, {
  //       title: 'Role',
  //       icon: 'fa-user-shield',
  //       children: [
  //         {
  //           title: 'Enregistrer un role',
  //           to: 'save-role',
  //         },
  //         {
  //           title: 'Liste des roles',
  //           to: 'list-role',
  //         },
  //       ],
  //     },
  //   ],
  // },

  /* {
    title: 'Utilisateurs',
    icon: "fa-user",
    children: [
      {
        title: 'Enregistrer un utilisateur',
        to: 'save-user',
      },
      {
        title: "Liste d'utilisateur",
        to: 'list-user',
      },
    ],
  }, */

  /* {
    title: 'Client',
    icon: 'fa-user',
    children: [
      {
        title: 'Liste des clients',
        to: 'list-client',
      },
    ],
  }, */
  /* {
    title: 'Locaux',
    icon: 'fa-building',
    children: [
      {
        title: 'Liste des locaux',
        to: 'list-emplacement',
      },
      {
        title: 'Liste de Type de locaux',
        to: 'list-type_local',
      },
    ],
  }, */
]
